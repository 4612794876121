import {
  type GlorifiedGuarantee,
  type MrpSmallOfficeGuaranteeGroupType,
  type PolicyGuaranteesType,
} from '@orus.eu/dimensions'
import adhesiveBandage from './adhesive-bandage.png'
import ambulance from './ambulance.png'
import assistance from './assistance.png'
import atteinteDroitPropriete from './atteinte_droit_intepropriete.png'
import atteinteReput from './atteinte_reputation.png'
import attentat from './attentat.png'
import bienDocuConfies from './bien_docu_confies.png'
import briefCase from './briefcase.png'
import brisDeGlace from './bris_de_glace.png'
import buildingConstruction from './building-construction.png'
import cannedFood from './canned-food.png'
import catastropheNat from './catastrophe_naturelle.png'
import contestationCreance from './contestation_creance.png'
import coutsProjet from './couts_projet.png'
import crown from './crown.png'
import degatsEaux from './degats_eaux.png'
import derelictHouse from './derelict-house.png'
import dommageElec from './dommage_electrique.png'
import earAid from './ear-with-hearing-aid.png'
import emeute from './emeute.png'
import esRcphAccidentalPollutionLiability from './esRcphAccidentalPollutionLiability.png'
import esRcphCrossLiability from './esRcphCrossLiability.png'
import esRcphDamageToEntrustedObjects from './esRcphDamageToEntrustedObjects.png'
import esRcphDamageToReputation from './esRcphDamageToReputation.png'
import esRcphEmployerCivilLiability from './esRcphEmployerCivilLiability.png'
import esRcphEntrustedOrSupervisedGoods from './esRcphEntrustedOrSupervisedGoods.png'
import esRcphInsuredLossForDamageOnWebsite from './esRcphInsuredLossForDamageOnWebsite.png'
import esRcphIntentionalOrMaliciousFaultOfEmployees from './esRcphIntentionalOrMaliciousFaultOfEmployees.png'
import esRcphKeyPersonLoss from './esRcphKeyPersonLoss.png'
import esRcphLegalAssistanceExpenses from './esRcphLegalAssistanceExpenses.png'
import esRcphLossOfYourDocuments from './esRcphLossOfYourDocuments.png'
import esRcphOperationalCivilLiability from './esRcphOperationalCivilLiability.png'
import esRcphOrganizationSaleAndExecutionOfCombinedServicesAndPackageTours from './esRcphOrganizationSaleAndExecutionOfCombinedServicesAndPackageTours.png'
import esRcphPostDeliveryCivilLiability1 from './esRcphPostDeliveryCivilLiability.png'
import esRcphProfessionalCivilLiability from './esRcphProfessionalCivilLiability.png'
import esRcphProfessionalIncapacityExpenses from './esRcphProfessionalIncapacityExpenses.png'
import esRcphProjectFeesReimbursement from './esRcphProjectFeesReimbursement.png'
import esRcphPsychologicalAssistanceExpenses from './esRcphPsychologicalAssistanceExpenses.png'
import esRcphPureFinancialLossLiability from './esRcphPureFinancialLossLiability.png'
import esRcphRentalCivilLiability from './esRcphRentalCivilLiability.png'
import esRcphSubsidiaryContractorLiability from './esRcphSubsidiaryContractorLiability.png'
import esRcphSubsidiaryMotorVehicleLiability from './esRcphSubsidiaryMotorVehicleLiability.png'
import esRcphTheft from './esRcphTheft.png'
import esRcphWorldwideCoverage from './esRcphWorldwideCoverage.png'
import evenementClimatique from './evenement_climatique.png'
import eyes from './eyes.png'
import faceWithTermometer from './face-with-thermometer.png'
import fautesPro from './fautes_pro.png'
import glasses from './glasses.png'
import healthWorker from './health-worker.png'
import hospital from './hospital.png'
import house from './house.png'
import incendie from './incendie.png'
import informatiqueMachine from './informatique_machine.png'
import laptop from './laptop.png'
import lawBook2 from './law-book-2.png'
import lawBook3 from './law-book-3.png'
import lawBook from './law-book.png'
import lockWithKey from './locked_key.png'
import lotus from './lotus.png'
import manquementsContract from './manquements_contract.png'
import mobilePhone from './mobile-phone.png'
import notebook from './notebook.png'
import nutAndBolt from './nut-and-bolt.png'
import personFeedingBaby from './person-feeding-baby.png'
import personMassage from './person-getting-massage.png'
import personInBed from './person-in-bed.png'
import personInWheelchair from './person-in-manual-wheelchair.png'
import perteDocu from './perte_docu.png'
import moneyWithWings from './perte_exploitation.png'
import pill from './pill.png'
import piratageSite from './piratage_site.png'
import pottedPlant from './potted-plant.png'
import recoursJuridiques from './recours_juridiques.png'
import remboursementPresta from './remboursement_presta.png'
import remplacementHommeCle from './remplacement_homme_cle.png'
import safetyVest from './safety-vest.png'
import screwdriver from './screwdriver.png'
import syringe from './syringe.png'
import taxi from './taxi.png'
import phone from './telephone.png'
import testTube from './test-tube.png'
import tooth from './tooth.png'
import toothbrush from './toothbrush.png'
import truck from './truck.png'
import vandalisme from './vandalisme.png'
import vol from './vol.png'
import whiteCane from './white-cane.png'
import zipperFace from './zipper-mouth-face.png'

export const guaranteeImages: {
  [key in PolicyGuaranteesType | GlorifiedGuarantee | MrpSmallOfficeGuaranteeGroupType]: string
} = {
  'rcda-rc-pro': lawBook3,
  ManquementsContract: manquementsContract,
  FautesProOuNegligence: fautesPro,
  RemboursementPresta: remboursementPresta,
  BienDocumentConfies: bienDocuConfies,
  AtteinteDroitPropriete: atteinteDroitPropriete,
  ContestationCreance: contestationCreance,
  CoutsdeProjet: coutsProjet,
  AtteinteReputation: atteinteReput,
  RemplacementHommeCle: remplacementHommeCle,
  RecoursJuridique: recoursJuridiques,
  PerteDocuments: perteDocu,
  PiratageSiteInternet: piratageSite,
  Attentat: attentat,
  BrisGlace: brisDeGlace,
  CatastropheNat: catastropheNat,
  DegatsEaux: degatsEaux,
  DommagesElec: dommageElec,
  Emeute: emeute,
  EvenementsClim: evenementClimatique,
  Incendie: incendie,
  InformatiqueMachine: informatiqueMachine,
  RespCivOccupant: lawBook3,
  Vandalisme: vandalisme,
  Vol: vol,
  publicLiability: notebook,
  fire: incendie,
  electricalDamage: dommageElec,
  waterDamage: degatsEaux,
  theft: vol,
  vandalisme: vandalisme,
  climaticEvent: evenementClimatique,
  breakage: screwdriver,
  naturalDisaster: catastropheNat,
  terrorism: attentat,
  assistance,
  BrisMateriel: screwdriver,
  MarchConservee: cannedFood,
  cyber: piratageSite,
  pj: recoursJuridiques,
  tdmi: laptop,
  AnalysesExamsLaboratoires: testTube,
  MedecinGeneraliste: faceWithTermometer,
  Paramedicaux: adhesiveBandage,
  ActesDePrevention: syringe,
  AidesAuditives: earAid,
  AppareillageEtProthesesAuditives: earAid,
  ChambreParticuliere: personFeedingBaby,
  ConfortHospitalier: phone,
  DentaireNonPrisEnCharge: toothbrush,
  FraisDeSejour: hospital,
  HonorairesEtablissements: briefCase,
  HonorairesPraticiens: healthWorker,
  HospitalisationDomicile: pottedPlant,
  TeleconsultationMedicale: mobilePhone,
  CoachingBienEtre: lotus,
  AccompagnementPsychologique: atteinteDroitPropriete,
  AideDomicile: house,
  TransportTaxi: taxi,
  Lentilles: eyes,
  MedecineNaturelle: personMassage,
  Medicaments: pill,
  MontureVerresComplexes: glasses,
  MontureVerresSimples: glasses,
  Orthodontie: zipperFace,
  PetitAppareillage: whiteCane,
  ProthesesDentaires: crown,
  ProthesesEtAppareillages: personInWheelchair,
  SejourAccompagnant: personInBed,
  SoinsDentaires: tooth,
  Transport: ambulance,
  rcDecennaleObligatoire: derelictHouse,
  rcDecennaleSousTraitance: safetyVest,
  rcDecennaleNonObligatoire: buildingConstruction,
  rcAvantApresReception: lawBook2,
  biensSurChantier: nutAndBolt,
  cyberDommagesPe: moneyWithWings,
  cyberDefaillanceFournisseur: truck,
  cyberDommagesTiers: lockWithKey,
  cyberFraudePiratage: piratageSite,
  esRcphLossOfYourDocuments: esRcphLossOfYourDocuments,
  esRcphPostDeliveryCivilLiability1: esRcphPostDeliveryCivilLiability1,
  esRcphEmployerCivilLiability1: esRcphEmployerCivilLiability,
  esRcphRentalCivilLiability: esRcphRentalCivilLiability,
  esRcphOperationalCivilLiability1: esRcphOperationalCivilLiability,
  esRcphProfessionalIncapacityExpenses: esRcphProfessionalIncapacityExpenses,
  esRcphLegalAssistanceExpenses: esRcphLegalAssistanceExpenses,
  esRcphPsychologicalAssistanceExpenses: esRcphPsychologicalAssistanceExpenses,
  esRcphIntentionalOrMaliciousFaultOfEmployees: esRcphIntentionalOrMaliciousFaultOfEmployees,
  esRcphProfessionalCivilLiability: esRcphProfessionalCivilLiability,
  esRcphWorldwideCoverage: esRcphWorldwideCoverage,
  esRcphOperationalCivilLiability2: esRcphOperationalCivilLiability,
  esRcphEmployerCivilLiability2: esRcphEmployerCivilLiability,
  esRcphPostDeliveryCivilLiability2: esRcphPostDeliveryCivilLiability1,
  esRcphInsuredLossForDamageOnWebsite: esRcphInsuredLossForDamageOnWebsite,
  esRcphKeyPersonLoss: esRcphKeyPersonLoss,
  esRcphDamageToReputation: esRcphDamageToReputation,
  esRcphProjectFeesReimbursement: esRcphProjectFeesReimbursement,
  esRcphSubsidiaryMotorVehicleLiability: esRcphSubsidiaryMotorVehicleLiability,
  esRcphSubsidiaryContractorLiability: esRcphSubsidiaryContractorLiability,
  esRcphAccidentalPollutionLiability: esRcphAccidentalPollutionLiability,
  esRcphDamageToEntrustedObjects: esRcphDamageToEntrustedObjects,
  esRcphCrossLiability: esRcphCrossLiability,
  esRcphPureFinancialLossLiability: esRcphPureFinancialLossLiability,
  esRcphTheft: esRcphTheft,
  esRcphOrganizationSaleAndExecutionOfCombinedServicesAndPackageTours:
    esRcphOrganizationSaleAndExecutionOfCombinedServicesAndPackageTours,
  esRcphEntrustedOrSupervisedGoods: esRcphEntrustedOrSupervisedGoods,
  mrpSmallOffice: house,
  mrpSmallOfficeIncendies: incendie,
  mrpSmallOfficeDegatsDesEaux: degatsEaux,
  mrpSmallOfficeVol: vol,
  mrpSmallOfficeVandalisme: vandalisme,
  mrpSmallOfficeEvenementsClimatiques: evenementClimatique,
  mrpSmallOfficeBrisDeGlace: brisDeGlace,
  mrpSmallOfficeCatastrophesNaturelles: catastropheNat,
  mrpSmallOfficeAttentats: attentat,
  mrpSmallOfficeOccupant: lawBook3,
  mrpSmallOfficeAssistance: assistance,
  defensePenale: lawBook2,
  fraisDefense: lawBook,
  perteDocuments: perteDocu,
  diffamation: atteinteReput,
  manqueContractuel: fautesPro,
  manqueObligationConseil: manquementsContract,
  rce: lawBook,
}
